
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    title: String,
    center: Boolean,
    scroll: Boolean,
    middle: Boolean,
    large: Boolean,
    header: 
    {
      type: Boolean,
      default: true
    },
  },
  emits: ['close'],
  data() {
    return {
      headerActive: Boolean(this.header),
    };
  },
  methods: {
    emitCustomEvent() {
      this.$emit('close', false);
    },
  },
})
export default class ModalComponent extends Vue { }
